<template>
  <div class="app-alert">
    <TransitionGroup name="slide">
      <div v-for="({ message, type }, index) in items" :key="index" class="alert" :class="typeStyle(type)" role="alert">
        {{ message }}
      </div>
    </TransitionGroup>
  </div>
</template>

<script setup>
defineProps({
  items: Array,
})
const typeStyle = (type) => (type === 'error' ? 'alert-danger' : 'alert-primary')
</script>

<style scoped lang="scss">
.app-alert {
  width: 400px;
  position: fixed;
  bottom: 10px;
  left: 50%;
  right: 50%;
  z-index: 99999;
  transform: translate(-50%, -50%);
}
.alert-danger {
  background: color('danger');
  padding: 20px;
  border-radius: 4px;
  text-align: center;
  color: white;
}
.alert-primary {
  background: color('el-primary');
  padding: 20px;
  border-radius: 4px;
  text-align: center;
  color: white;
}
.slide-enter-from,
.slide-leave-to {
  opacity: 0;
  transform: translateY(-30px);
}
.slide-enter-active,
.slide-leave-active {
  transition: all 0.5s ease;
}
.slide-enter-to,
.slide-leave-from {
  opacity: 1;
  transform: translateY(0px);
}
</style>
