import DashboardMapCustomOverlay from '@/components/dashboard/DashboardMapCustomOverlay.vue'
import DashboardMapGroupInfo from '@/components/dashboard/DashboardMapGroupInfo.vue'
import {
  mountVueComponent,
  makeCustomOverlay,
  removeCustomOverlay,
  findCenterOfCoordinates,
  movedCenter,
} from '@/utils/kakaoMaps'

export const mapStores = defineStore(
  'mapStore',
  () => {
    // state
    const map = ref(null)
    const dataList = ref([])
    const overlay = ref([])
    const infoOverlay = ref([])
    const zoomLevel = ref(6)
    // getter
    const getMap = computed(() => map.value)
    const getDataList = computed(() => dataList.value)
    const getOverlay = computed(() => overlay.value)
    const getInfoOverlay = computed(() => infoOverlay.value)
    const getZoomLevel = computed(() => zoomLevel.value)
    //actions
    function setMap(el, markerList, showOveray) {
      map.value = null
      overlay.value = []
      infoOverlay.value = []
      if (!window.kakao) return
      const container = el
      // const center = markerList
      //   ? findCenterOfCoordinates(markerList)
      //   : {
      //       lat: 37.559901686,
      //       lon: 126.942906303,
      //     }
      const center = !_.isEmpty(markerList)
        ? {
            lat: markerList[0].lat,
            lon: markerList[0].lon,
          }
        : {
            lat: 37.559901686,
            lon: 126.942906303,
          }
      // updateDataList(markerList)
      const options = {
        center: new window.kakao.maps.LatLng(center.lat, center.lon),
        level: getZoomLevel.value,
      }
      const maps = new window.kakao.maps.Map(container, options)
      updateMap(maps)
      setZoomable(false)
      // 디바운스를 위한 변수와 함수
      let zoomChangedTimeout = null
      function debounceZoomChanged() {
        // 이전 타임아웃을 취소하고 새로운 타임아웃 설정
        clearTimeout(zoomChangedTimeout)
        zoomChangedTimeout = setTimeout(() => {
          const zoom = maps.getLevel()
          updateZoom(zoom)
        }, 300) // 디바운스 지연 시간 설정 (여기서는 300ms)
      }
      // zoom_changed 이벤트에 디바운스 적용
      window.kakao.maps.event.addListener(maps, 'zoom_changed', debounceZoomChanged)
      if (showOveray) showOveray()
      return maps
    }

    function updateMap(maps) {
      map.value = maps
    }
    function updateDataList(arr) {
      dataList.value = arr
    }
    function updateOverlayList(arr) {
      overlay.value = arr
    }
    function updateInfoOverlayList(arr) {
      infoOverlay.value = arr
    }
    function updateZoom(level) {
      zoomLevel.value = level
    }
    function setCustomOverlay(data, renderComponent, event, sort = false) {
      if (!getMap.value) return
      data.forEach((v, i) => {
        const content = document.createElement('div')
        const modalWrapper = document.createElement('div')
        const overlays = makeCustomOverlay(getMap.value, { lat: v.lat, lon: v.lon }, content, {}, i)
        mountVueComponent(renderComponent ? renderComponent : DashboardMapCustomOverlay, content, {
          ...v,
          clickEvent: () => {
            event(v, modalWrapper, overlays, i)
          },
        })
        overlay.value.push(overlays)
      })
    }
    function setPolygon(data) {
      console.log(data)
      const polygonPath = data.map((v) => new window.kakao.maps.LatLng(v.lat, v.lon))
      const polygon = new window.kakao.maps.Polygon({
        path: polygonPath, // 그려질 다각형의 좌표 배열입니다
        strokeWeight: 3, // 선의 두께입니다
        strokeColor: '#39DE2A', // 선의 색깔입니다
        strokeOpacity: 0.8, // 선의 불투명도 입니다 1에서 0 사이의 값이며 0에 가까울수록 투명합니다
        strokeStyle: 'longdash', // 선의 스타일입니다
        fillColor: '#A2FF99', // 채우기 색깔입니다
        fillOpacity: 0.7, // 채우기 불투명도 입니다
      })
      // const polygonPath = [
      //   new window.kakao.maps.LatLng(33.45133510810506, 126.57159381623066),
      //   new window.kakao.maps.LatLng(33.44955812811862, 126.5713551811832),
      //   new window.kakao.maps.LatLng(33.449986291544086, 126.57263296172184),
      //   new window.kakao.maps.LatLng(33.450682513554554, 126.57321034054742),
      //   new window.kakao.maps.LatLng(33.451346760004206, 126.57235740081413),
      // ]
      // // 지도에 표시할 다각형을 생성합니다
      // const polygon = new window.kakao.maps.Polygon({
      //   path: polygonPath, // 그려질 다각형의 좌표 배열입니다
      //   strokeWeight: 3, // 선의 두께입니다
      //   strokeColor: '#39DE2A', // 선의 색깔입니다
      //   strokeOpacity: 0.8, // 선의 불투명도 입니다 1에서 0 사이의 값이며 0에 가까울수록 투명합니다
      //   strokeStyle: 'longdash', // 선의 스타일입니다
      //   fillColor: '#A2FF99', // 채우기 색깔입니다
      //   fillOpacity: 0.7, // 채우기 불투명도 입니다
      // })
      // // 지도에 다각형을 표시합니다
      polygon.setMap(getMap.value)
    }
    function setZoomable(zoomable) {
      if (!getMap.value) return
      getMap.value.setZoomable(zoomable)
    }
    function removeOverlay() {
      overlay.value.map((v) => v.setMap(null))
      overlay.value = []
    }
    function showInfoOverlay(component, element, overlays, props, options, maintain = false) {
      mountVueComponent(component, element, {
        ...props,
      })
      if (!maintain) infoOverlay.value = removeCustomOverlay(infoOverlay.value)
      const pos = overlays.getPosition()
      const newInfoOverlay = makeCustomOverlay(map.value, { lat: pos.Ma, lon: pos.La }, element, { ...options })
      infoOverlay.value.push(newInfoOverlay)
    }
    return {
      map,
      dataList,
      overlay,
      infoOverlay,
      zoomLevel,
      getMap,
      getDataList,
      getOverlay,
      getInfoOverlay,
      getZoomLevel,
      setMap,
      updateDataList,
      updateMap,
      updateOverlayList,
      updateInfoOverlayList,
      updateZoom,
      showInfoOverlay,
      setCustomOverlay,
      removeOverlay,
      setPolygon,
    }
  },
  {
    persist: {
      enabled: true,
    },
  },
)
