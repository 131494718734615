import { createApp, h } from 'vue'
/**
 * ! 커스텀오버레이 컴포넌트 렌더러
 * @param {object} renderComponent : 싱글파일 컴포넌트
 * @param {element} targetElement : 컴포넌트를 담을 element ex)createElment
 * @param {object} propsData : 컴포넌트에 전달할 props
 */
function mountVueComponent(renderComponent, targetElement, propsData = null) {
  const app = createApp({
    render: () => h(renderComponent, { ...propsData }),
  })
  app.mount(targetElement)
}
/**
 * ! 커스텀오버레이 컴포넌트 등록
 * @param {object} maps : 생성한 지도
 * @param {object} pos : 오버레이를 생성할 좌표 {lat: xx, lon:xx}
 * @param {element} content : 지도위에 그려낼 엘리먼트
 * @param {object} options : 기타 카카오지도 커스텀오버레이 옵션
 * @param {any} uniqe : 생성하는 마커에 유니크값 부여
 */
function makeCustomOverlay(maps, pos, content, options = {}, uniqe = null) {
  if (!window?.kakao) return
  const newCustomOverlay = new window.kakao.maps.CustomOverlay({
    map: maps,
    position: new window.kakao.maps.LatLng(pos.lat, pos.lon),
    content,
    ...options,
  })
  if (uniqe) newCustomOverlay.uniqe_key = uniqe
  return newCustomOverlay
}
/**
 * ! 커스텀오버레이 컴포넌트 제거
 * @param {array} overLayList : 지도위에 그려낸 오버레이 리스트
 * @param {object} overlay : 지도위에 그려낼 오버레이
 * @return 지도위에 그려낼 오버레이가 없거나, 특정 오버레이의 유니크 값이 없을경우 모든 오버레이를 없애고 빈 배열 리턴
 * @return 지도위에 그려낼 오버레이가 있거나, 오버레이의 유니크값이 있을경우 그려낼 오버레이를 제외한 모든 오버레이 제거 및 새로 그려낸 오버레이 리스트배열을 리턴
 */
function removeCustomOverlay(overLayList, overlay) {
  if (!window?.kakao) return
  if (overlay && overlay.uniqe_key) {
    overlay.setMap(null)
    const { uniqe_key } = overlay
    return overLayList.filter((v) => v.uniqe_key !== uniqe_key)
  } else {
    overLayList.map((v) => v.setMap(null))
    return []
  }
}
/**
 * ! 좌표들의 중심점 구하기
 * @param {array} coords : 좌표 리스트
 */
function findCenterOfCoordinates(coords) {
  if (!coords || coords.length === 0) {
    return null
  }

  let totalLat = 0
  let totalLon = 0

  coords.forEach((coord) => {
    totalLat += coord.lat
    totalLon += coord.lon
  })

  return {
    lat: totalLat / coords.length,
    lon: totalLon / coords.length,
  }
}
/**
 * ! 센터이동
 * @param {object} map 지도
 * @param {number} lat 위도
 * @param {number} lon 경도
 * @param {boolean} smooth 이동타입
 */
function movedCenter(map, lat, lon, smooth = true) {
  if (!window?.kakao) return
  const centerPos = new window.kakao.maps.LatLng(lat, lon)
  if (smooth) map.panTo(centerPos)
  else map.setCenter(centerPos)
}
/**
 * ! 줌컨트롤러
 * @param {object} map 지도
 * @param {number} level 줌 레벨
 */
function zoomController(map, level) {
  if (!window?.kakao) return
  map.setLevel(level)
}
function searchAddrFromCoords(coords, callback) {
  if (!window?.kakao) return
  // 주소-좌표 변환 객체를 생성합니다
  const geocoder = new window.kakao.maps.services.Geocoder()
  // 좌표로 행정동 주소 정보를 요청합니다
  geocoder.coord2RegionCode(coords.getLng(), coords.getLat(), callback)
}

export {
  mountVueComponent,
  makeCustomOverlay,
  searchAddrFromCoords,
  removeCustomOverlay,
  findCenterOfCoordinates,
  movedCenter,
  zoomController,
}
