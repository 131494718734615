import { storeToRefs } from 'pinia'

import { userContentStore } from '@/stores/contentBasicData'
import { mapStores } from '@/stores/maps'
import { useUserStore } from '@/stores/userStore'
import { useLocalStore } from '@/stores/localStore'
import { usePusherStore } from '@/stores/pusherStore'

/**
 * ! 스토어 가져오기
 * ? 지금처럼 사용하려면 모든 스토어의 state 가 겹치면 안된다.
 * @returns {object} store
 */
export default function useStore() {
  return {
    ...userContentStore(),
    ...mapStores(),
    ...useUserStore(),
    ...useLocalStore(),
    ...usePusherStore(),
    ...storeToRefs(userContentStore()),
    ...storeToRefs(mapStores()),
    ...storeToRefs(useUserStore()),
    ...storeToRefs(useLocalStore()),
    ...storeToRefs(usePusherStore()),
  }
}
