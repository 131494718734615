import { jsonp } from "vue-jsonp";

export const userContentStore = defineStore(
  "myStore",
  () => {
    // state
    const client = ref([]);
    const agency = ref([]);
    const conType = ref([
      { contentType: "상업광고", value: "A" },
      { contentType: "공익광고", value: "P" },
      { contentType: "미디어아트", value: "M" },
      { contentType: "일반", value: "N" }
    ]);
    const siData = ref([]);
    const doData = ref([]);
    const allDoData = ref([]);

    //actions
    async function getClientData() {
      const list = await $fetchApi({
        url: `/v1/api/common/ad-company/CLIENT/list`,
        method: "GET"
      });
      client.value = list?.data || [];
    }

    async function getAgencyData() {
      const list = await $fetchApi({
        url: `/v1/api/common/ad-company/AGENCY/list`,
        method: "GET"
      });
      agency.value = list?.data || [];
    }

    const vKey = import.meta.env.VITE_VWORLD_KEY;
    const local = import.meta.env.VITE_BASE_URL;

    async function getSiData() {
      const siURL = `https://api.vworld.kr/req/data?key=${ vKey }&domain=${ local }&service=data&version=2.0&request=getfeature&format=json&size=1000&page=1&geometry=false&attribute=true&crs=EPSG:3857&geomfilter=BOX(13663271.680031825,3894007.9689600193,14817776.555251127,4688953.0631258525)&data=LT_C_ADSIDO_INFO`;

      try {
        const data = await jsonp(siURL)
        const inputData = data?.response?.result?.featureCollection?.features
        if (inputData && Array.isArray(inputData)) {
          const siObject = inputData.map((item) => ({
            city_cd: item.properties.ctprvn_cd,
            city_name: item.properties.ctp_kor_nm
          }));
          siData.value = siObject.sort(function(a, b) {
            if (a.city_name < b.city_name) {
              return -1;
            }
            if (a.city_name > b.city_name) {
              return 1;
            }
            return 0;
          });
        }
      }
      catch (error) {
        console.error(error);
      }
    }

    async function getDoData(dodata) {
      const doURL = `https://api.vworld.kr/req/data?key=${ vKey }&domain=${ local }&service=data&version=2.0&request=getfeature&format=json&size=1000&page=1&geometry=false&attribute=true&crs=EPSG:3857&geomfilter=BOX(13663271.680031825,3894007.9689600193,14817776.555251127,4688953.0631258525)&data=LT_C_ADSIGG_INFO`;
      try {
        const data = await jsonp(doURL, { attrfilter: "sig_cd:like:" + dodata })
        const inputData = data?.response?.result?.featureCollection?.features
        if (inputData && Array.isArray(inputData)) {
          console.log(inputData)
          const doObject = inputData.filter(item => item.properties.sig_cd.startsWith(dodata)).map(item => ({
            do_cd: item.properties.sig_cd,
            do_name: item.properties.sig_kor_nm
          }))
          doData.value = doObject.sort(function(a, b) {
            if (a.do_name < b.do_name) {
              return -1;
            }
            if (a.do_name > b.do_name) {
              return 1;
            }
            return 0;
          });
          console.log(doData.value)
        }
      }
      catch (error) {
        console.error();
      }
    }

    async function getAllDoData() {
      const doURL = `https://api.vworld.kr/req/data?key=${ vKey }&domain=${ local }&service=data&version=2.0&request=getfeature&format=json&size=1000&page=1&geometry=false&attribute=true&crs=EPSG:3857&geomfilter=BOX(13663271.680031825,3894007.9689600193,14817776.555251127,4688953.0631258525)&data=LT_C_ADSIGG_INFO`;
      try {
        const data = await jsonp(doURL)
        const inputData = data?.response?.result?.featureCollection?.features
        if (inputData && Array.isArray(inputData)) {
          const alldoObject = inputData.map((item) => ({
            do_cd: item.properties.sig_cd,
            do_name: item.properties.sig_kor_nm
          }))
          doData.value = alldoObject.sort(function(a, b) {
            if (a.do_name < b.do_name) {
              return -1;
            }
            if (a.do_name > b.do_name) {
              return 1;
            }
            return 0;
          });
          console.log(doData.value)
        }
      }
      catch (error) {
        console.error(error);
      }
    }

    return {
      client,
      agency,
      conType,
      siData,
      doData,
      allDoData,
      getClientData,
      getAgencyData,
      getSiData,
      getDoData,
      getAllDoData
    };
  },
  {
    persist: {
      enabled: true
    }
  }
);
