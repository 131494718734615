<template>
    <div class="menuWrap">
        <h1 class="logo mb-1" @click="$router.push({ name: 'home' })">
            <a :class="{ sLogo: isCollapse }" href="#">DOOH service</a>
        </h1>
        <el-menu
                :collapse="isCollapse"
                :default-active="activeIndex"
                :router="true"
                background-color="#58C65D"
                class="el-menu-vertical-demo mainMenu"
                text-color="#fff"
                @close="handleClose"
                @open="handleOpen">
            <el-menu-item index="/dashboard">
                <div class="icon dash"></div>
                <span class="menu">대시보드</span>
                <div class="box"></div>
            </el-menu-item>

            <!-- <el-menu-item index="2">
              <el-icon><Guide /></el-icon>
              <span>미디어사이트</span>
            </el-menu-item> -->

            <el-menu-item :class="{ active: $route.meta.depthName === '미디어사이트' }" index="/site/0">
                <div class="icon media"></div>
                <span class="menu">미디어</span>
                <div class="box"></div>
            </el-menu-item>
            <el-menu-item :class="contentActiveClass" index="/content/0/1">
                <div class="icon contents"></div>
                <span class="menu">콘텐츠</span>
                <div class="box"></div>
            </el-menu-item>
            <el-menu-item :class="scheduleActiveClass" index="/schedule/0/1">
                <div class="icon schedule"></div>
                <span class="menu">스케줄</span>
                <div class="box"></div>
            </el-menu-item>
            <el-menu-item :class="monitorActiveClass" index="/monitor/0">
                <div class="icon monitor"></div>
                <span class="menu">모니터링</span>
                <div class="box"></div>
            </el-menu-item>
            <!--      <div style="padding-left: 1.5em; color: #1e5d09;">-&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;</div>-->
            <!--      <el-menu-item index="/OperationData">-->
            <!--        <div class="icon oper"></div>-->
            <!--        <span class="menu">운영데이터</span>-->
            <!--        <div class="box"></div>-->
            <!--      </el-menu-item>-->
            <!--      <el-menu-item index="/pusher" :class="pusherActiveClass">-->
            <!--        <div class="icon oper"></div>-->
            <!--        <span class="menu">Pusher Test</span>-->
            <!--        <div class="box"></div>-->
            <!--      </el-menu-item>-->
            <!--      <el-menu-item index="/fielder" :class="fielderActiveClass">-->
            <!--        <div class="icon oper"></div>-->
            <!--        <span class="menu">현장 관리</span>-->
            <!--        <div class="box"></div>-->
            <!--      </el-menu-item>-->

            <!--      <el-menu-item index="/adMediaSite/adMediaList">-->
            <!--        <div class="icon ad"></div>-->
            <!--        <span class="menu">광고미디어관리</span>-->
            <!--        <div class="box"></div>-->
            <!--      </el-menu-item>-->
        </el-menu>
    </div>
    <div class="toggleMenu">
        <!-- <el-radio-group v-model="isCollapse" style="margin-bottom: 20px">
          <el-radio-button :label="false">expand</el-radio-button>
          <el-radio-button :label="true">collapse</el-radio-button>
        </el-radio-group> -->
        <el-icon color="white" size="25" @click="isCollapse = !isCollapse">
            <Expand />
        </el-icon>
    </div>
</template>

<script setup>
import { onMounted, ref, onBeforeUnmount, watchEffect, computed } from 'vue'
const router = useRouter()

import { Document, Menu as IconMenu, Location, Setting } from '@element-plus/icons-vue'

const activeIndex = ref('')
const route = useRoute()
const isCollapse = ref(false)

const handleOpen = (key, keyPath) => {
    console.log(key, keyPath)
}
const handleClose = (key, keyPath) => {
    console.log(key, keyPath)
}

const width = ref(window.innerWidth)
const height = ref(window.innerHeight)

const handleResize = () => {
    width.value = window.innerWidth
    height.value = window.innerHeight
}

onMounted(() => {
    window.addEventListener('resize', handleResize)
})

onBeforeUnmount(() => {
    window.removeEventListener('resize', handleResize)
})

const menuView = () => {
    isCollapse.value = true
    // if (width.value < 1200) {
    //   isCollapse.value = true
    // } else {
    //   isCollapse.value = false
    // }
}

watchEffect(menuView)

const contentActiveClass = computed(() => {
    const depthNames = [
        '콘텐츠',
        '콘텐츠등록',
        '콘텐츠수정',
        '콘텐츠보기',
        '싱크콘텐츠보기',
        '싱크콘텐츠수정',
        '싱크콘텐츠생성',
    ]
    return depthNames.includes(route.meta.depthName) ? 'active' : ''
})
const scheduleActiveClass = computed(() => {
    const depthNames = ['스케줄', '스케줄등록', '스케줄정보보기', '미디어 그룹별 보기']
    return depthNames.includes(route.meta.depthName) ? 'active' : ''
})
const monitorActiveClass = computed(() => {
    const depthNames = ['모니터링', '모니터링정보']
    return depthNames.includes(route.meta.depthName) ? 'active' : ''
})
const pusherActiveClass = computed(() => {
    const depthNames = ['Pusher Test']
    return depthNames.includes(route.meta.depthName) ? 'active' : ''
})
const fielderActiveClass = computed(() => {
    const depthNames = ['현장 관리']
    return depthNames.includes(route.meta.depthName) ? 'active' : ''
})
</script>

<style lang="scss">
h1.logo {
  padding: 20px 0;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;

  color: white;
  /* text-align: center; */
  font-size: 30px;

  a {
    text-indent: -9999px;
    display: block;
    background-image: url(@/assets/logo_white.png);
    background-repeat: no-repeat;
    background-position: center;
    -webkit-background-size: 100%;
    background-size: 100%;
    width: 150px;
    height: 40px;
    transition: 0.3s;

    &.sLogo {
      width: 70px;
      //background-image: url(@/assets/logo_sWhite.png);
    }
  }
}

.el-menu {
  border-right: 0 none !important;
}

.el-menu-vertical-demo:not(.el-menu--collapse) {
  width: 200px;
}

.menuWrap {
  background: #58c65d;
  position: relative;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
  /* background: #545c64; */
  /* border-right: 1px solid #545c64; */
}

.toggleMenu {
  position: fixed;
  bottom: 20px;
  left: 20px;
  z-index: 100;
}

.mainMenu {
  position: sticky;
  top: 10px;
  left: 0;
}

.el-menu-item {
  color: #19441b;

  .icon {
    margin-left: 18px;
    width: 20px;
    height: 20px;
    //margin-right: 10px;
    background-image: url(@/assets/icons/menuicon.png);
    @include LGM {
      margin-right: 0;
      margin-left: 0px;
    }
    &.dash {
      background-position: 0 0;
    }
    &.media {
      background-position: 0 -20px;
    }
    &.contents {
      background-position: 0 -40px;
    }
    &.schedule {
      background-position: 0 -60px;
    }
    &.monitor {
      background-position: 0 -80px;
    }
    &.oper {
      background-position: 0 -100px;
    }
    &.ad {
      background-position: 0 -120px;
    }
  }
  .menu {
    padding-left: 10px;
  }
  .box {
    transition: 0.3s;
    position: absolute;
    right: 0px;
    border: 0px none;
    border-color: transparent #e3e6eb transparent transparent;
  }

  &.is-active,
  &.active {
    .icon {
      background-image: url(@/assets/icons/menuicon.png);
      background-position: -20px 0;

      &.dash {
        background-position: -20px 0;
      }
      &.media {
        background-position: -20px -20px;
      }
      &.contents {
        background-position: -20px -40px;
      }
      &.schedule {
        background-position: -20px -60px;
      }
      &.monitor {
        background-position: -20px -80px;
      }
      &.oper {
        background-position: -20px -100px;
      }
      &.ad {
        background-position: -20px -120px;
      }
    }
    .box {
      border: 15px solid #e3e6eb;
      border-color: transparent #e3e6eb transparent transparent;
    }
  }
}

.el-menu--collapse {
  width:96px;
  //margin: 0 16px;
  //background: skyblue;
  .menu {
    padding-left: 16px;
  }

  &.is-active,
  &.active {
    .box {
      border: 10px solid #e3e6eb;
    }
  }
}
.el-menu-item.is-active,
.el-menu-item.active {
  color: #fff !important;
}
</style>
