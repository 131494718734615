import { reactive } from 'vue'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'

const { $Confirm } = useCommon()

dayjs.extend(utc)
dayjs.extend(timezone)

/**
 * @param {any} value
 * @returns {boolean}
 */
export const $isEmpty = (value) =>
  value === null ||
  value === undefined ||
  typeof value === 'undefined' ||
  value === false ||
  String(value).toLowerCase() === 'false' ||
  value === 0 ||
  value === '0' ||
  Number.isNaN(value) ||
  (typeof value === 'string' && value.trim() === '')

/**
 * ! $isSet
 * @param {any} value
 * @returns {boolean}
 */
export const $isSet = (value) => !$isEmpty(value)

/**
 * ! $isUrl
 * @param {any} value
 * @returns {boolean}
 */
export const $isUrl = (value) => {
  try {
    return Boolean(new URL(value))
  } catch (err) {
    return false
  }
}

/**
 * ! $parseJwt :: json 을 파싱해주는 함수
 * @param {any} _token
 * @returns {string}
 */
export const $parseJwt = (_token) => {
  const base64Url = _token.split('.')[1]
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
  return decodeURIComponent(
    atob(base64)
      .split('')
      .map(function (c) {
        return `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`
      })
      .join(''),
  )
}

/**
 * ! $createEl :: DOM 생성
 * @param {string} elName
 * @returns {HTMLElement}
 */
export const $createEl = (elName) => document.createElement(elName)

/**
 * ! $setAttrEl :: DOM 속성변경
 * @param {HTMLElement} el
 * @param {string} key
 * @param {string} value
 * @returns {HTMLElement}
 */
export const $setAttrEl = (el, key, value) => el.setAttribute(key, value)

/**
 * ! $defaultChecked :: 배열에서 값을 찾는 함수 (찾는값이 없다면 무조건 첫번째 배열 리턴)
 * @param {array} array 검색할 배열
 * @param {any} keyName 배열이 객체라면 객체의 키값 일차원 배열이라면 index값
 * @param {any} value 객체의 값 혹은 배열 index의 값
 * @returns {array} 찾아낸 배열을 리턴 값이 없다면 원본 배열의 0번째값
 */
export const $defaultChecked = (array, keyName, value) => {
  const filter = array.filter((v, i) => {
    if (v[keyName] === value || i === keyName) return v
  })
  return filter.length === 0 ? array[0] : filter[0]
}

/**
 * ! $getPeriodRang :: 날짜 간격 계산
 * @param {string} value [TODAY, 7DAY, 15DAY, 1MONTH, 3MONTH, 6MONTH] 중 고정 픽스값
 * @returns {object} 간격값과 날짜 단위 객체리턴
 */
export const $getPeriodRang = (value) => {
  switch (value) {
    case 'TODAY':
      return { value: 0, unit: 'day' }
    case '7DAY':
      return { value: 7, unit: 'day' }
    case '15DAY':
      return { value: 15, unit: 'day' }
    case '1MONTH':
      return { value: 1, unit: 'month' }
    case '3MONTH':
      return { value: 3, unit: 'month' }
    case '6MONTH':
      return { value: 6, unit: 'month' }
    default:
      return { value: 1, unit: 'day' }
  }
}

export const $periodDateCalc = (typeList, type = false) => {
  const periodDefaultType = $defaultChecked(typeList, 'default', true)
  const periodRang = $getPeriodRang(periodDefaultType.value)
  const { value, unit } = periodRang
  return {
    value: periodDefaultType.value,
    startDate: type
      ? dayjs().format('YYYY-MM-DD')
      : dayjs()
          .add(value * -1, unit)
          .format('YYYY-MM-DD'),
    endDate: type ? dayjs().add(value, unit).format('YYYY-MM-DD') : dayjs().format('YYYY-MM-DD'),
  }
}

export const $formSet = ($refs) => {
  $refs.DATE_PICKER_PERIOD.value = []
}

/**
 * ! object 로 받아서 FormData 객체 리턴
 * @param {object} obj
 * @returns {object} FormData 객체
 */
export const $makeForm = (obj) => {
  const formData = new FormData()
  for (const [key, value] of Object.entries(obj)) {
    formData.append(key, value)
  }
  return formData
}

/**
 * ! 문자열을 받아서 이미지/비디오/파일 경로 리턴
 * @param {string} src
 * @param {string} _type
 * @returns {string} 서버절대주소
 */
export const $getPath = (src, _type) => {
  // const type = _type === 'I' ? 'IMAGE' : _type === 'F' ? 'FILE' : 'BASE'
  let type = 'BASE'
  switch (_type) {
    case 'I':
      type = 'IMAGE'
      break
    case 'F':
      type = 'FILE'
      break
    case 'M':
      type = 'MEDIA'
      break
    default:
      type = 'BASE'
      break
  }
  return import.meta.env['VITE_' + type] + src
}

/**
 * ! 객체를 받아 reactive 객체를 리턴
 * @param {object} initialData
 * @returns {object} reactive 객체
 */
export const $reactive = (initialData) => reactive(_.cloneDeep(initialData))

/**
 * ! 객체를 받아 reactive 객체를 assign
 * @param {object} data
 * @param {object} _initialData
 * @param {array} keepData
 * @returns {object} 초기화 된 data 객체
 */
export const $reset = (data, _initialData, keepData = []) => {
  const initialData = _.cloneDeep(_initialData)
  keepData.forEach((v) => {
    initialData[v] = data[v]
  })
  return _.assign(data, initialData)
}

/**
 * ! 문자열을 받아 첫 글자만 대문자로 변경
 * @param {string} str
 * @returns {string} 첫 글자만 대문자로 변경하여 리턴
 */
export const $upperFirst = (str) => str.replace(/^[a-z]/, (char) => char.toUpperCase())

/* *
 * 날짜관련 함수들
 * =========================================
 * yyyy-MM-dd'T'XXX : 2023-02-01T+09:00
 * yyyy-MM-dd'T'HH:mmXXX : 2023-02-01T17:32+09:00
 * =========================================
 * console.log(  'TZ === ', dayjs('2023-02-01T17:32+09:00').tz(dayjs.tz.guess(), false).format()  )
 * console.log(  'server - Date === ', convertDt.tz(dayjs.tz.guess(), false).format('YYYY-MM-DDTZ') )
 * console.log(  'client - Date === ', convertDt.tz(dayjs.tz.guess(), false).format('YYYY-MM-DD') )
 * console.log(  'server - DateTime === ', convertDt.tz(dayjs.tz.guess(), false).format('YYYY-MM-DDTHH:mmZ') )
 * console.log(  'client - DateTime === ', convertDt.tz(dayjs.tz.guess(), false).format('YYYY-MM-DD HH:mm:ss') )
 *  */

/**
 * ! 날짜를 받아 유효성 체크 후 결과 반환
 * @param {string | date | undefined } str
 * @returns {date} Date 객체
 */
export const $convertDt = (str) => {
  return dayjs(str).isValid()
    ? dayjs(str)
    : dayjs(str.replace('T+', 'T00:00:00+')).isValid()
    ? dayjs(str.replace('T+', 'T00:00:00+'))
    : false
}

/**
 * ! 날짜를 받아 서버/클라이언트용 Date 객체로 리턴
 * @param {string | date | undefined } str
 * @returns {date | null} Date 객체
 */
export const $date = (str, unit) => {
  const convertDt = $convertDt(str)
  const newUnit = unit || '-'
  return convertDt
    ? {
        server: convertDt.tz(dayjs.tz.guess(), false).format(`YYYY${newUnit}MM${newUnit}DDTZ`),
        client: convertDt.tz(dayjs.tz.guess(), false).format(`YYYY${newUnit}MM${newUnit}DD`),
      }
    : null
}

/**
 * ! 날짜를 받아 서버/클라이언트용 DateTime 객체로 리턴
 * @param {string | date | undefined } str
 * @returns {date | null} Date 객체
 */
export const $dateTime = (str, unit) => {
  const convertDt = $convertDt(str)
  const newUnit = unit || '-'
  return convertDt
    ? {
        server: convertDt.tz(dayjs.tz.guess(), false).format(`YYYY${newUnit}MM${newUnit}DDTHH:mmZ`),
        client: convertDt.tz(dayjs.tz.guess(), false).format(`YYYY${newUnit}MM${newUnit}DD HH:mm:ss`),
      }
    : null
}

/**
 * ! 한글체크
 * @param {string} str
 * @returns {string} 첫 글자만 대문자로 변경하여 리턴
 *
 */
export const $checkKor = (str) => {
  const regExp = /[ㄱ-ㅎㅏ-ㅣ가-힣]/g
  return regExp.test(str)
}

/**
 * ! 문자열을 받아 받침이 있는지 없는지 리턴
 * @param {string} str
 * @returns {string} 첫 글자만 대문자로 변경하여 리턴
 *
 */
export const $isEndWithConsonant = (str) => {
  if ($checkKor(str)) {
    const finalChrCode = str.charCodeAt(str.length - 1)
    // 0 = 받침 없음, 그 외 = 받침 있음
    const finalConsonantCode = (finalChrCode - 44032) % 28
    return finalConsonantCode !== 0
  } else {
    return !['a', 'e', 'i', 'o', 'u', 'y', 'f', 'r', 's', 'v', 'z'].includes(str.charAt(str.length - 1))
  }
}

/**
 * ! Pager 객체를 받아 서버에 보내줄 객체 리턴
 * @param {object} pager
 * @returns {object}
 *
 */
export const $makeSendPager = (pager) => {
  return {
    pageNumber: pager.currentPage - 1,
    pageSize: pager.pageSize,
  }
}

export const $biteUnitSizeUp = (bite, unit, decimal) => {
  let calcUnit = ''
  switch (unit) {
    case 'KB':
      calcUnit = 1024
      break
    case 'MB':
      calcUnit = 1024 * 10
      break
    case 'G':
      calcUnit = 1024 * 100
      break
    case 'T':
      calcUnit = 1024 * 1000
      break
    default:
      calcUnit = 1
      break
  }
  return {
    size: decimal ? Math.round(bite / calcUnit) : bite / calcUnit,
    unit,
  }
}

export const $getLocalIpAddress = async () => {
  return new Promise((resolve, reject) => {
    const pc = new RTCPeerConnection({ iceServers: [] })
    pc.createDataChannel('')
    pc.createOffer()
      .then((offer) => pc.setLocalDescription(offer))
      .catch(reject)

    pc.onicecandidate = (iceEvent) => {
      if (!iceEvent || !iceEvent.candidate) {
        reject(new Error('Could not get local IP address'))
        return
      }

      const ipAddress = iceEvent.candidate.address

      // Check if the IP address is IPv4
      if (ipAddress.includes('.')) {
        pc.close()
        resolve(ipAddress)
      }
    }
  })
}
export const $toLink = (url) => {
  if (!url) return
  const originUrl = [
    'localhost',
    'ais-localdev.ais.shinhan.com',
    'aistudio-adm.soopdev.shinhan.com',
    'aistudio-adm.soop.shinhan.com',
  ]
  try {
    const newUrl = new URL(url)
    const { pathname, hostname } = newUrl
    if (originUrl.includes(hostname)) router.push(pathname)
    else window.open(url)
  } catch (e) {
    router.push(url)
  }
}
export const $handleBeforeUnload = (event, data) => {
  if (data) {
    const confirmationMessage = '페이지를 떠나시겠습니까?'
    event.returnValue = confirmationMessage
    return confirmationMessage
  }
}

export const $handleBeforeRouteLeave = (to, from, next, post) => {
  if (!post) {
    if (window.confirm('페이지를 떠나시겠습니까? 변경사항이 저장되지 않을 수 있습니다.')) {
      next()
    } else {
      next(false)
    }
  } else {
    next()
  }
}
