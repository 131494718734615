// import * as ElementPlusIconsVue from '@element-plus/icons-vue'

export default {
  install(app, options) {

    //% Global Components Init
    // app.component('PagerCp', PagerCp)

  },
}
