export const useLocalStore = defineStore("local", () => {


  /*
  * INIT
  *  */
  // 이메일 저장
  const savedUserEmail = ref("")

  // 캡쳐 지연 시간 기본값
  const waitCaptureDefaultTime = ref(15 * 1000)

  // 캡쳐 지연 시간 저장값
  const waitCaptureSavedTime = ref(null)


  /*
  * GETTER
  *  */
  // 이메일 저장
  const getSavedUserEmail = computed(() => savedUserEmail.value)

  // 캡쳐 지연 기본값
  const getWaitCaptureDefaultTime = computed(() => waitCaptureDefaultTime.value)

  // 캡쳐 지연 저장값(남은 시간)
  const getWaitCaptureSavedTime = computed(() => waitCaptureSavedTime.value)


  /*
  * ACTION
  *  */
  // 이메일 저장
  function updateSavedUserEmail(_userEmail) {
    savedUserEmail.value = _userEmail
    return savedUserEmail.value
  }

  // 캡쳐 지연 저장값(남은 시간 저장)
  function updateWaitCaptureSavedTime(time) {
    waitCaptureSavedTime.value = time
    return waitCaptureSavedTime.value
  }

  return {
    savedUserEmail,
    waitCaptureDefaultTime,
    waitCaptureSavedTime,

    getSavedUserEmail,
    getWaitCaptureDefaultTime,
    getWaitCaptureSavedTime,

    updateSavedUserEmail,
    updateWaitCaptureSavedTime,
  }
}, {
  persist: {
    enabled: true,
    storage: localStorage,
  },
})
