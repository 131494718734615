<script setup>

</script>

<template>
  <div class="fielder-header-cp">
    <h1 class="logo"><a href="/fielder" class="">DOOH service</a></h1>
    <h1 class="title">현장 설치 콘솔</h1>
  </div>
</template>

<style scoped lang="scss">
.fielder-header-cp {
  background-color: #58c65d;
  display: flex;
  align-items: center;

  h1.logo {
    padding: 20px;
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-size: 30px;
  }
  h1.title {
    color: #edfced;
    font-size: 2em;
  }
}
</style>