import { createRouter, createWebHistory, createWebHashHistory } from 'vue-router'
import useRouterHook from '@/mixins/useRouterHook'
import { routes } from './routes'
let loading = null

console.log(import.meta.env.VITE_FLAG)

const router = createRouter({
  base: '/',
  history: import.meta.env.VITE_FLAG === "gabia" ? createWebHashHistory() : createWebHistory(import.meta.env.BASE_URL),
  routes,
})

export default useRouterHook(router, loading)
