import { START_LOCATION } from "vue-router"
import usePersist from "@/mixins/usePersist"
import useStore from "@/stores/back/store_back"
import useCommon from "@/mixins/useCommon"
import { $getLocalIpAddress, $makeForm } from "@/modules"

const { $Alert, $Confirm, $Error } = useCommon()

export default function useRouterHook(router, loading) {

  //! Loading Bar Show
  router.beforeEach(async (to, from, next) => {
    loading = ElLoading.service(Define.LOADING_OPTION)
    next()
  })

  //% 권한 설정
  //! Token Healthcheck
  router.beforeEach(async (to, from, next) => {
    const user = window?.sessionStorage?.user
    if (to.name === "login" && user) next("/dashboard")
    else if (to.name !== "login" && !user) next("/login")
    else next()
  })


  //% 로딩바 가리기
  router.afterEach((to, from) => {
    loading?.close()
  })

  return router
}
