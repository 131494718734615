import jwt_decode from 'jwt-decode'

export const useUserStore = defineStore(
  'user',
  () => {
    // 초기화를 위한 기본값
    // const userInit = {
    //   aud: [],
    //   userName: '',
    //   scope: [],
    //   exp: 0,
    //   authorities: [],
    //   jti: '',
    //   clientId: '',
    //   accessToken: '',
    //   refreshToken: '',
    //   expiresIn: 0,
    // }

    //% STATE
    const user = ref(null)

    //% GETTER
    /**
     * ! user 정보 가져오기
     * @returns {object} User 정보 모든값
     */
    const getUser = computed(() => user.value)

    /**
     * ! AccessToken 정보 가져오기
     * @returns {string} AccessToken
     */
    const getAccessToken = computed(() => user.value?.accessToken || null)

    /**
     * ! AccessToken 정보 가져오기
     * @returns {string} RefreshToken
     */
    const getRefreshToken = computed(() => user.value?.refreshToken || null)

    /**
     * ! AccessToken 정보 가져오기
     * @returns {object} user
     */
    const getUserInfo = computed(() => user.value?.userInfo || null)

    /**
     * ! Token (Access, Refresh) 만료 여부 확인
     * @returns {boolean} result
     * TODO :: accessToken, refreshToken 로 토큰 만료 체크 - 토큰검증 x
     */
    const getIsValidToken = computed(() => {
      if (user.value?.accessToken && user.value?.refreshToken) {
        const accessToken = jwt_decode(user.value.accessToken)
        const refreshToken = jwt_decode(user.value.refreshToken)
      }
    })

    //% ACTION
    /**
     * ! user 정보수정
     * @param {object} _user
     * @returns {object} user
     */
    function updateUser(_user) {
      if (_user && _user.accessToken) {
        user.value = {
          ..._user,
          userInfo: parseUserInfo(_user.accessToken)
        }
        // user.value.userInfo = JSON.parse($parseJwt(_user.accessToken))
      }
      return user.value
    }

    /**
     * ! user 정보수정
     * @param {object} _userInfo
     * @returns {object} user.userInfo
     */
    function fetchUserInfo(_userInfo) {
      if (_userInfo) {
        user.value.userInfo = Object.assign(user.value.userInfo, _userInfo)
      }
      return user.value.userInfo
    }

    /**
     * ! accessToken 정보수정
     * @param {object} _token
     * @returns {string} accessToken
     */
    function updateAccessToken(_token) {
      user.value.accessToken = _token
      return user.value.accessToken
    }

    /**
     * ! refreshToken 정보수정
     * @param {object} _token
     * @returns {string} refreshToken
     */
    function updateRefreshToken(_token) {
      user.value.refreshToken = _token
      return user.value.refreshToken
    }

    /**
     * ! verifyToken 토큰 유효성 체크
     * @returns {boolean} refreshToken
     */
    async function verifyToken() {
      const formData = new FormData()
      formData.append('token', getAccessToken.value)
      const rs = await $fetchLogin({
        url: '/v1/api/auth/access',
        method: 'POST',
        contentType: 'FORM',
        requestType: 'AccessToken Validation',
        data: formData,
      })
      console.log(rs)
    }

    /**
     * ! verifyToken 토큰 시간 유효성 체크
     * @returns {boolean}
     */
    async function checkExpireToken() {
      user.value.iat
    }

    /**
     * ! verifyToken 토큰 유효성 체크
     * @param {object} accessToken
     * @returns {boolean} refreshToken
     */
    function parseUserInfo(accessToken) {
      const userInfo = jwt_decode(accessToken)
      console.log("%c ===== userInfo =====", "background: #222; color: #ea6947")
      console.log(userInfo)
      return userInfo
    }


    /**
     * ! user 로그아웃
     * @returns {object} 초기화 객체
     */
    async function logoutUser() {
      try {
        const url = import.meta.env.VITE_API_AUTH + '/v1/api/auth/logout'
        const config = { headers: { Authorization: 'Bearer ' + getAccessToken.value } }
        if (getAccessToken.value) await $axios.post(url, {}, config)
      } catch (err) {
        console.log(err)
      }
      window.sessionStorage.clear()
      router.push({ name: 'login' })
      return user.value
    }

    return {
      user,
      getUser,
      getAccessToken,
      getRefreshToken,
      getUserInfo,
      updateUser,
      fetchUserInfo,
      logoutUser,
      updateAccessToken,
      updateRefreshToken,
      verifyToken,
    }
  },
  {
    persist: {
      enabled: true,
      storage: sessionStorage,
    },
  },
)
