<template>
  <div v-show="shouldShowEmergency && props.emergencyVisible" class="emergency-wrapper" @click="$emit('update:emergencyVisible', false)">
    <div class="emergency-wrap">
      <div class="emergency-txt">
        {{ props.deviceCd }} 의 <span class="emergency-status">{{ props.emergencyMessage }}</span> 발생
      </div>
    </div>
    <!--<div class="btn-wrap">-->
    <!--  <span class="btn-close" @click="closeTodayEmergency">오늘 하루 닫기</span>-->
    <!--  <span style="padding: 0 8px;">|</span>-->
    <!--  <span class="btn-close" @click="$emit('update:emergencyVisible', false)">닫기</span>-->
    <!--</div>-->
  </div>
</template>

<script setup>

const emit = defineEmits()
const props = defineProps({
  emergencyVisible: {
    type: Boolean,
    default: false
  },
  emergencyMessage: {
    type: String,
    default: ""
  },
  deviceCd: {
    type: String,
    default: ""
  }
})

//? 긴급송출 - 오늘 하루 안보기
const shouldShowEmergency = computed(() => {
  const hideTodayEmergency = localStorage.getItem('hideTodayEmergency')
  if (hideTodayEmergency) {
    const currentDate = new Date().toISOString().split('T')[0]
    return currentDate > hideTodayEmergency
  }
  return true
})

//? 창 닫기
function closeTodayEmergency() {
  const currentDate = new Date()
  const tomorrow = new Date(currentDate)
  tomorrow.setDate(currentDate.getDate() + 1)
  localStorage.setItem("hideTodayEmergency", tomorrow.toISOString().split("T")[0])
  emit("update:emergencyVisible", false)
}

</script>

<style lang="scss" scoped>
.emergency-wrapper {
  width: 100%;
  height: 100vh;
  background-color: rgba(101, 29, 29, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: fixed;
  z-index: 999;
  left: 0;
  top: 0;

  .emergency-wrap {
    background-color: rgba(0, 0, 0, 0.8);
    min-width: 600px;
    min-height: 200px;
    width: 60%;
    display: flex;
    align-items: center;
    justify-content: center;

    .emergency-txt {
      color: white;
      font-size: 2em;
      .emergency-status {
        color: #eedaa0;
      }
    }
  }

  .btn-wrap {
    padding-top: 0.5em;
    color: #e8d292;

    .btn-close {
      padding: 0.125em;
      cursor: pointer;
      letter-spacing: -0.125em;
    }
  }
}
</style>
