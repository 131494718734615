import pinia from './stores'
import routes from './routes'
import plugins from '@/plugins'
import ElementPlus from 'element-plus'
import VueResizeObserver from 'vue-resize-observer'
import ko from 'element-plus/dist/locale/ko.mjs'
// import Vidle from 'v-idle-3'
import 'swiper/css'
import 'element-plus/dist/index.css'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
import './assets/scss/common.scss'

//% Global Components
import App from './App.vue'

//% App init
const app = createApp(App)

//% Global Properties Init
app.use(pinia) // Store  Inject
app.use(routes) // Router Inject
app.use(ElementPlus, { locale: ko }) // ElementPlus inject
app.use(plugins) // Plugin inject
app.use(VueResizeObserver) // Resize Observer inject
// app.use(Vidle) // idle check

for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component)
}

//% App Mount
router.isReady().then(() => {
  app.mount('#app')
})
