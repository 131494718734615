import Pusher from "pusher-js"

export const usePusherStore = defineStore("pusher", () => {

    //% STATE
    const pusher = ref(new Pusher(import.meta.env.VITE_PUSHER_KEY, { cluster: import.meta.env.VITE_PUSHER_CLUSTER }))

    //% GETTER
    /**
     * ! pusher 정보 가져오기
     * @returns {object} Pusher 정보 모든값
     */
    const getPusher = computed(() => pusher.value)

    return {
      pusher,
      getPusher,
    }
  },
  {
    persist: {
      enabled: true,
    },
  },
)
