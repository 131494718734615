<script setup>
const initialData = {
  list: [],
}

const data = $reactive(initialData)
</script>

<template>
  <div class="fielder-wrapper">
    <fielder-header-cp />
    <div class="content-wrapper">
      <el-table :data="data.list" style="width: 100%" table-layout="auto">
          <!--<el-table-column type="selection" width="55" />-->
          <el-table-column prop="num" label="No" width="50">
            <template #default="scope">
              {{ scope.$index + 1 + (currentPage - 1) * data.pager.pageSize }}
            </template>
          </el-table-column>
          <el-table-column prop="location" label="위치">
            <template #default="scope">
              <div>{{ findCity(scope.row.location1) }} {{ findDo(scope.row.location2) }}</div>
            </template>
          </el-table-column>
          <el-table-column prop="mediaSiteName" label="미디어사이트">
            <template #default="scope">
              <div @click="mediaSiteModi(scope.row)">
                {{ scope.row.mediaSiteName }}
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="mediaGroupName" label="미디어그룹">
            <template #default="scope">
              <div @click="mediaGroupModi(scope.row)">
                {{ scope.row.mediaGroupName }}
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="mediaName" label="미디어이름">
            <template #default="scope">
              <div @click="mediaModi(scope.row)">
                {{ scope.row.mediaName }}
              </div>
            </template>
          </el-table-column>
          <el-table-column label="미디어정보">
            <el-table-column prop="name" label="디바이스명">
              <template #default="scope">
                <span class="mDeviceTitle"> {{ scope.row.serialNum }}</span>
              </template>
            </el-table-column>
            <el-table-column prop="state" label="상태">
              <template #default="scope">
                <span class="state red"></span>
              </template>
            </el-table-column>
            <el-table-column prop="monitor" label="모니터">
              <template #default="scope">
                <span class="mostate"></span>
              </template>
            </el-table-column>
            <el-table-column prop="snaps" label="스크린샷">
              <el-icon>
                <CameraFilled/>
              </el-icon>
            </el-table-column>
            <el-table-column label="로그" width="70">
              <template #default="scope">
                <el-button @click="pageMovieView(scope.row.deviceCd)">로그</el-button>
              </template>
            </el-table-column>
          </el-table-column>
        </el-table>
    </div>
  </div>
</template>

<style scoped lang="scss">
.fielder-wrapper {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;

  .content-wrapper {
    flex-grow: 1;
    padding: 1em;
  }
}
</style>