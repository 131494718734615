<template>
  <!-- 로그인 -->
  <el-scrollbar v-if="useLoginLayout">
    <div class="mainWrap w-100">
      <router-view />
    </div>
  </el-scrollbar>

  <!-- 현장 관리 -->
  <FielderListPage v-else-if="useFielderLayout" />

  <!-- 홈 -->
  <!--<el-scrollbar v-else-if="useHomeLayout">-->
  <!--  <div class="container flex">-->
  <!--    <div class="mainWrap w-100">-->
  <!--      <HeaderWrapper />-->
  <!--      <div class="p-2">-->
  <!--        <router-view />-->
  <!--      </div>-->
  <!--    </div>-->
  <!--  </div>-->
  <!--</el-scrollbar>-->

  <!-- 기본 -->
  <el-scrollbar v-else>
    <div class="container flex">
      <div class="menuWrap">
        <MenuManagePage />
      </div>
      <div class="mainWrap w-100">
        <HeaderWrapper />
        <div class="p-2">
          <router-view />
        </div>
      </div>
    </div>
  </el-scrollbar>

  <!-- 로그인 타임 아웃 -->
  <!--<alert-view-cp />-->

  <!-- 긴급 알림 -->
  <alert-emergency-cp
    v-model:device-cd="data.deviceCd"
    v-model:emergency-message="data.emergencyMessage"
    v-model:emergency-visible="data.emergencyVisible"
  />

  <!-- 알림-->
  <alert-view-cp :items="alerts" />

</template>

<script setup>
import Pusher from 'pusher-js'
import { useAlert } from "@/composables/alert"

const route = useRoute()
const { alerts } = useAlert()

const {
  user,
  conType,
  client,
  agency,
  getClientData,
  getAgencyData,
  siData,
  getSiData,
  doData,
  getDoData,
  allDoData,
  getAllDoData,
  getUser,
  getUserInfo,
  pusher,
} = useStore()

console.log(import.meta.env.VITE_BASE_URL)

// 현장 관리 사용
const useFielderLayout = computed(() => route?.name === "fielder")

// 로그인 사용
const useLoginLayout = computed(() => route?.name === "login")


//? 긴급송출 데이터
const initPusherData = {
  appKey: import.meta.env.VITE_PUSHER_KEY,
  cluster: import.meta.env.VITE_PUSHER_CLUSTER,
  userId: getUserInfo?.value?.comp || "",
  pusher: null,
  emergencyMessage: "",
  emergencyVisible: false,
  deviceCd: ""
}

//? 긴급송출
const data = $reactive(initPusherData)

//? 긴급송출 - Pusher Bind
function subscribePusher() {

  Pusher.logToConsole = true

  // Pusher 생성
  // data.pusher = new Pusher(data.appKey, { cluster: data.cluster })
  data.pusher = pusher.value

  // did:mnt-imp. m-door
  // did:mnt-dst. m-impact

  // Pusher 구독 시작
  data.pusher.subscribe(data.userId)

  console.log(data.pusher)

  // Pusher 수신 콜백 - MCU
  data.pusher.bind("m-door", (rs) => {
    data.emergencyVisible = true
    data.deviceCd = rs?.message?.deviceCd || "DE000000000"
    if (rs.message.value === "opened")
      data.emergencyMessage = `도어 오픈`
    else
      data.emergencyMessage = `도어 닫힘`
  })

  data.pusher.bind("m-impact", (rs) => {
    data.emergencyVisible = true
    data.deviceCd = rs?.message?.deviceCd || "DE000000000"
    data.emergencyMessage = `충격 상태`
  })
}


onMounted(() => {
  getAllDoData()
  getDoData(11)
  getSiData()
})

watch(() => getUserInfo?.value, (nv, ov) => {
  if (nv) {
    getClientData()
    getAgencyData()
    subscribePusher()
  }
}, {
  deep: true,
  immediate: true
})

</script>

<style lang="scss">
.container {
  min-height: 100vh;

  .menuWrap {
    min-height: 100%;
  }
}

.mainWrap {
  background: #f1f1f1;
  //background: #e3e6eb;
  min-width: 700px;
  //height: 100vh;
}

.container::-webkit-scrollbar {
  width: 5px;
  background-color: black;
}
</style>
