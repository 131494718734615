import HomeView from '@/views/home/HomeView.vue'


export const routes = [
  {
    path: '/',
    name: 'home',
    redirect: to => {
      const { getUser } = useStore()
      return getUser?.value ? "/dashboard" : "/login"
    }
  },
  {
    path: '/login',
    name: 'login',
    meta: {
      requiresAuth: true,
      roles: []
    },
    component: () => import('@/views/common/LoginPage.vue'),
  },
  {
    path: '/dashboard',
    name: 'DashBoard',
    meta: {
      depthName: '대시보드',
      requiresAuth: true,
      roles: []
    },
    component: () => import('@/views/dashboard/DashboardPageView.vue'),
  },
  {
    path: '/site/:page',
    alias: ['/site'],
    name: 'site-page',
    meta: {
      depthName: '미디어사이트',
      requiresAuth: true,
      roles: []
    },
    component: () => import('@/views/site/SiteListPage.vue'),
  },
  {
    path: '/sitesearch/:page',
    alias: ['/sitesearch'],
    name: 'site-search-result',
    meta: {
      depthName: '미디어사이트',
      requiresAuth: true,
      roles: []
    },
    component: () => import('@/views/site/SiteSearchResultPage.vue'),
  },
  {
    // path: '/content/:tab/:page',
    path: '/content/:gubun/:page',
    name: 'content-list',
    alias: ['/content/'],
    meta: {
      depthName: '콘텐츠',
      requiresAuth: true,
      roles: []
    },
    component: () => import('@/views/content/ContentListPage.vue'),
  },
  {
    path: '/content/:gubun/:page/manage',
    name: 'content-manage',
    alias: ['/content/:gubun/:page/manage'],
    meta: {
      depthName: '콘텐츠등록',
      requiresAuth: true,
      roles: []
    },
    component: () => import('@/views/content/ContentManagePage.vue'),
  },
  {
    path: '/content/:gubun/:page/manage/:id',
    name: 'content-view',
    meta: {
      depthName: '콘텐츠보기',
      requiresAuth: true,
      roles: []
    },
    component: () => import('@/views/content/ContentManageViewPage.vue'),
  },
  {
    path: '/content/:gubun/:page/manage/:id/edit',
    name: 'content-edit',
    meta: {
      depthName: '콘텐츠수정',
      requiresAuth: true,
      roles: []
    },
    component: () => import('@/views/content/ContentManageEditPage.vue'),
  },
  {
    path: '/content/:gubun/:page/sync',
    name: 'content-sync',
    alias: ['/content/:gubun/:page/manage'],
    meta: {
      depthName: '싱크콘텐츠생성',
      requiresAuth: true,
      roles: []
    },
    component: () => import('@/views/content/ContentSyncPage.vue'),
  },
  {
    path: '/content/:gubun/:page/sync/:id/edit',
    name: 'sync-edit',
    meta: {
      depthName: '싱크콘텐츠수정',
      requiresAuth: true,
      roles: []
    },
    component: () => import('@/views/content/ContentSyncEditPage.vue'),
  },
  {
    path: '/content/:gubun/:page/sync/:id',
    name: 'sync-view',
    meta: {
      depthName: '싱크콘텐츠보기',
      requiresAuth: true,
      roles: []
    },
    component: () => import('@/views/content/ContentSyncViewPage.vue'),
  },
  {
    path: '/schedule/:tab/:page',
    name: 'Schedule-list',
    alias: ['/schedule/'],
    meta: {
      depthName: '스케줄',
      requiresAuth: true,
      roles: []
    },
    component: () => import('@/views/schedule/ScheduleListPage.vue'),
  },
  {
    path: '/schedule/:tab/:page/manage',
    name: 'Schedule-manage',
    meta: {
      depthName: '스케줄등록',
      requiresAuth: true,
      roles: []
    },
    component: () => import('@/views/schedule/ScheduleManagePage.vue'),
  },
  {
    path: '/schedule/:tab/:page/manage/:id',
    name: 'schedule-view',
    meta: {
      depthName: '스케줄정보보기',
      requiresAuth: true,
      roles: []
    },
    component: () => import('@/views/schedule/ScheduleManageViewPage.vue'),
  },
  {
    path: '/schedule/:tab/:page/manage/:id/edit',
    name: 'schedule-edit',
    meta: {
      depthName: '스케줄정보수정',
      requiresAuth: true,
      roles: []
    },
    component: () => import('@/views/schedule/ScheduleManageEditPage.vue'),
  },
  {
    path: '/monitor/:page',
    name: 'monitor-list',
    meta: {
      depthName: '모니터링',
      requiresAuth: true,
      roles: []
    },
    component: () => import('@/views/monitor/MonitorListPage.vue'),
  },
  // {
  //   path: '/monitor/:page',
  //   name: 'monitor-view',
  //   meta: { depthName: '모니터링정보' },
  //   component: () => import('../components/monitor/MonitorInforDeviceWrapper.vue'),
  // },
  {
    path: '/monitor/:page/:seq',
    name: 'monitor-view',
    meta: {
      depthName: '모니터링정보',
      requiresAuth: true,
      roles: []
    },
    component: () => import('@/views/monitor/MonitorManageViewPage.vue'),
  },

  // {
  //   path: '/Monitor',
  //   name: 'Monitoring',
  //   meta: { depthName: '모니터링' },
  //   component: () => import('../views/monitor/MonitorManagerPage.vue'),
  //   children: [
  //     {
  //       path: 'MonitorList',
  //       name: 'MonitorList',
  //       component: () => import('../components/monitor/MonitorListDeviceWrapper.vue'),
  //     },
  //     {
  //       path: 'MonitorInfo',
  //       name: 'MonitorInfo',
  //       component: () => import('../components/monitor/MonitorInforDeviceWrapper.vue'),
  //     },
  //   ],
  // },
  {
    path: '/MyAccount',
    name: 'MyAccount',
    meta: { depthName: '내정보' },
    component: () => import('@/views/myaccount/MyAccountPage.vue'),
      children: [
        {
          path: '/MyAccountInfor',
          name: 'MyAccountInfor',
          meta: { depthName: '담당자관리' },
          component: () => import('@/components/myaccount/MyAccountInforWrapper.vue'),
        },
        {
          path: '/MyAccountManager',
          name: 'MyAccountManager',
          meta: { depthName: '담당자관리' },
          component: () => import('@/components/myaccount/MyAccountManagerWrapper.vue'),
        },
        {
          path: '/MyAccountAds',
          name: 'MyAccountAds',
          meta: { depthName: '광고주/광고대행사' },
          component: () => import('@/components/myaccount/MyAccountAdsWrapper.vue'),
        },

      ],
  },
  {
    path: '/admin',
    name: 'admin',
    component: () => import('@/views/admin/AdminPage.vue'),
  },
  {
    path: '/adMediaSite',
    name: 'adMediaSite',
    component: () => import('@/views/admedia/adMediaSitePage.vue'),
    children: [
      {
        path: 'adMediaList',
        name: 'adMediaList',
        component: () => import('@/components/admedia/adMediaSiteListWrapper.vue'),
      },
    ],
  },
  {
    path: '/OperationData',
    name: 'OperationData',
    meta: {
      depthName: '운영데이터',
      requiresAuth: true,
      roles: []
    },
    component: () => import('@/views/operdata/OperDataPage.vue'),
  },
  {
    path: '/pusher',
    name: 'Pusher',
    meta: {
      depthName: 'Pusher Test',
      requiresAuth: true,
      roles: []
    },
    component: () => import('@/views/_temp/Pusher.vue'),
  },
  {
    path: '/fielder',
    name: 'fielder',
    meta: {
      depthName: '현장 관리',
      requiresAuth: true,
      roles: []
    },
    component: () => import('@/views/fielder/FielderListPage.vue'),
  },
  {
    path: '/sample',
    alias: ['/sample'],
    name: 'sample-page',
    meta: {
      depthName: '샘플사이트',
      requiresAuth: true,
      roles: []
    },
    component: () => import('@/views/sample/sampleListPage.vue'),
  },
]
