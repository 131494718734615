<template>
    <div class="headerWrapper flex">
        <!--    <el-page-header v-if="route?.name != 'home'">
          <template #content>
            <span class="sitenNavi text-large font-600 mr-3"> {{ route.meta.depthName }} </span>
          </template>
        </el-page-header>
        <div v-else>
          <h1><a href="./">DOOH service</a></h1>
        </div>-->

        <div v-if="route?.name !== 'home'">
            <!--      <span class="sitenNavi">-->
            <!--        <el-icon><House /></el-icon><el-icon><ArrowRight /></el-icon> {{ route.meta.depthName }}-->
            <!--        <el-icon><ArrowRight /></el-icon>-->
            <!--        {{ route.meta.depthName }}-->
            <!--      </span>-->
            <span class="sitenNavi">
<!--        <el-breadcrumb separator="/">-->
                <!--          <el-breadcrumb-item :to="{ path: '/' }"-->
                <!--            ><el-icon><House /></el-icon-->
                <!--          ></el-breadcrumb-item>-->
                <!--          &lt;!&ndash;          <el-breadcrumb-item>{{ urlTitle }}</el-breadcrumb-item>&ndash;&gt;-->
                <!--          <el-breadcrumb-item>{{ route.meta.depthName }}</el-breadcrumb-item>-->
                <!--        </el-breadcrumb>-->
        <h3 class="title w60">
          {{ route.meta.depthName }}
        </h3>
      </span>
        </div>
        <div v-else>
            <h1><a href='../../../../back/20230818/src/components/layout'>DOOH service</a></h1>
        </div>

        <div class="utilMenu flex">
            <!--      <div class="iMenu flex mr-2 gap-2">-->
            <!--        <div class="i_item">-->
            <!--          <el-badge :value="1" class="item">-->
            <!--            <el-icon :size="25"><UserFilled /></el-icon>-->
            <!--          </el-badge>-->
            <!--        </div>-->

            <!--        <div class="i_item">-->
            <!--          <el-badge :value="1" class="item">-->
            <!--            <el-icon :size="25"><Tools /></el-icon>-->
            <!--          </el-badge>-->
            <!--        </div>-->
            <!--        <div class="i_item">-->
            <!--          <el-badge :value="1" class="item">-->
            <!--            <el-icon :size="25"><Comment /></el-icon>-->
            <!--          </el-badge>-->
            <!--        </div>-->
            <!--        <div class="i_item">-->
            <!--          <el-badge :value="1" class="item">-->
            <!--            <el-icon :size="25"><Opportunity /></el-icon>-->
            <!--          </el-badge>-->
            <!--        </div>-->
            <!--      </div>-->
            <div class="avatar flex gap-1 pointer mr-1" style="align-items: center;" @click="router.push({ name: 'MyAccountInfor' })">
                <img alt="" class="w-100 h-100" src="@/assets/img/user.png" />
                <div class="userId flex">
                    {{ getUserInfo?.aud || "" }}
                </div>
            </div>
            <!--<el-button type="danger" @click="router.push({ name: 'admin' })">admin</el-button>-->
            <el-button v-if="getUserInfo" type="danger" @click="logoutUser">LOGOUT</el-button>
            <!--<el-button type="info" @click="router.push({ name: 'DashBoard' })">DASHBOARD</el-button>-->
        </div>
    </div>
</template>

<script setup>
const { user, getUserInfo, getUser, logoutUser } = useStore()
const router = useRouter()
const route = useRoute()
import { onUpdated } from 'vue'
const urlTitle = ref(null)

onUpdated(() => {
    const path = route.path
    const pathSections = path.split('/')
    urlTitle.value = pathSections[1].toUpperCase()

    console.log(getUserInfo)
})
</script>

<style lang="scss">
.headerWrapper {
  justify-content: space-between;
  box-shadow: 0 0 12px rgba(0, 0, 0, 0.2);
  position: relative;
  z-index: 10;
  background: white;
  padding: 25px 25px;


  h1 {
    a {
      text-indent: -9999px;
      display: block;
      background-image: url(@/assets/logo.png);
      background-repeat: no-repeat;
      width: 136px;
      height: 36px;
    }
  }
  .sitenNavi {
    white-space: nowrap;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 10px;
    color: color('w50');
    height: 100%;
  }
}

.utilMenu {
  white-space: nowrap;
  gap: 5px;
  .avatar {
    .userId {
      gap: 3px;
      span {
        display: flex;
        justify-content: center;
        align-items: center;
      }
      h3,
      .com {
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: bold;
      }
    }
  }
  .iMenu {
    @include SMM {
      display: none;
    }
  }
  .i_item {
    display: flex;
    justify-content: center;
    align-items: center;
    color: color('primary');
    cursor: pointer;
  }
  .el-button + .el-button {
    margin-left: 0;
  }
}
</style>
