export class Define {
  //% pager 초기값
  static PAGER_INIT = {
    page: 1,
    total: 0,
    pageCount: 10,
    pagerCount: 5,
    pageTotal: 1,
    startIdx: 0,
    pageStart: 1,
    pageEnd: 1,
    pagePrev: 1,
    pageNext: 1,
    pagerPrev: 1,
    pagerNext: 1,
    pagerArr: [],
  }

  //% datepicker 검색타입 유형
  static DATE_PICKER_TYPE = [
    { label: '기간별', value: '0' },
    { label: '일별', value: '1' },
    { label: '월별', value: '2' },
  ]

  //% datepicker 기간 유형
  static DATE_PICKER_PERIOD = [
    {
      label: '오늘',
      value: 'TODAY',
    },
    {
      label: '7일',
      value: '7DAY',
      default: true,
    },
    {
      label: '15일',
      value: '15DAY',
    },
    {
      label: '1개월',
      value: '1MONTH',
    },
    {
      label: '3개월',
      value: '3MONTH',
    },
    {
      label: '6개월',
      value: '6MONTH',
    },
  ]
  static MANAGE_DATE_PICKER_PERIOD = [
    {
      label: '오늘',
      value: 'TODAY',
    },
    {
      label: '7일',
      value: '7DAY',
      default: true,
    },
    {
      label: '15일',
      value: '15DAY',
    },
    {
      label: '30일',
      value: '1MONTH',
    },
  ]

  //alert message type
  static MESSAGE_GROUP_TYPE = [
    {
      text: '내용',
      status: 'UPDATE',
      value: 'content',
    },
    {
      text: '팝업',
      status: 'UPDATE',
      value: 'popup',
    },
    {
      text: '배너',
      status: 'UPDATE',
      value: 'banner',
    },
    {
      text: '이미지',
      status: 'UPDATE',
      value: 'image',
    },
    {
      text: '내용',
      status: 'DELETE',
      value: 'content',
    },
    {
      text: '팝업',
      status: 'DELETE',
      value: 'popup',
    },
    {
      text: '배너',
      status: 'DELETE',
      value: 'banner',
    },
    {
      text: '이미지',
      status: 'DELETE',
      value: 'image',
    },
  ]

  //% ElForm - label width
  static FORM_LABEL_WIDTH = '180px'

  //? Full Screen Loading options
  static LOADING_OPTION = {
    lock: true,
    background: 'rgba(255, 255, 255, 0.85)',
    customClass: 'el-loading-custom',
  }

  static LOADING_OPTION_TRANSPARENT = {
    lock: true,
    background: 'transparent',
    customClass: 'el-loading-custom',
  }

  //? 전체, 노출, 비노출
  static VISIBLE_RADIO_ALL = [
    { label: '전체', value: null },
    { label: '노출', value: '0' },
    { label: '비노출', value: '1' },
  ]

  static VISIBLE_RADIO = [
    { label: '노출', value: true },
    { label: '비노출', value: false },
  ]

  //? 사용, 미사용: 사용 / 미사용
  static USE_RADIO = [
    { label: '사용', value: true },
    { label: '미사용', value: false },
  ]

  //? DatePicker Type
  static DATE_SELECT = [
    { label: '등록일', value: '0' },
    { label: '수정일', value: '1' },
  ]

  static PERIOD_TYPE = [
    { label: '상시', value: true },
    { label: '기간', value: false },
  ]

  //? Pager Default Value
  static PAGER_DEFAULT = {
    currentPage: 0,
    pageSize: 12,
    total: 0,
    pagerCount: 5,
  }

  static PAGER_NINE = {
    currentPage: 0,
    pageSize: 9,
    total: 0,
    pagerCount: 5,
  }

  static PAGER_SIX = {
    currentPage: 0,
    pageSize: 6,
    total: 0,
    pagerCount: 5,
  }

  //? 소팅
  static SORT_TYPE = [
    { label: '내림차순', value: '0' },
    { label: '오름차순', value: '1' },
  ]

  //? 이미지등록 유형:  첨부 - 편집
  static IMAGE_TYPE = [
    { label: '파일 첨부', value: '0' },
    { label: '이미지 편집', value: '1' },
  ]

  //? 게시글 검색 타입
  static BOARD_SEARCH_TYPE = [
    { label: '전체', value: '0' },
    { label: '제목', value: '1' },
    { label: '내용', value: '2' },
    { label: '작성자', value: '3' },
  ]

  //? 게시글 검색 답변 타입
  static ANSWER_SEARCH_TYPE = [
    { label: '전체', value: '0' },
    { label: '답변', value: '1' },
    { label: '미답변', value: '2' },
  ]

  //? 관리자 권한 검색 타입
  static ADMIN_SEARCH_TYPE = [
    { label: '전체', value: '0' },
    { label: '이름', value: '1' },
    { label: '행번', value: '2' },
  ]

  //? 관리자 권한 기간 타입
  static ADMIN_DATE_SELECT = [
    { label: '등록일', value: '0' },
    { label: '최근 접속일', value: '1' },
  ]

  //? 관리자 권한 등록 타입
  static ADMIN_AUTHORITY_TYPE = [
    { label: '메뉴 전체 권한', value: '0' },
    { label: '메뉴 선택 권한', value: '1' },
  ]

  //? 등록일 오름/내림차순
  static SORT_CREATED_DATE = [
    { label: '등록일 ↓', value: 'desc' },
    { label: '등록일 ↑', value: 'asc' },
  ]

  //? 파일 이미지 확장자 명
  static IMAGE_FILE_EXTENSIONS_NAME = ['jpg', 'png', 'gif', 'jpeg']

  //? 파일 비디오 확장자 명
  static VIDEO_FILE_EXTENSIONS_NAME = [
    'WMV',
    'AVI',
    'ASF',
    'FLV',
    'MPEG2-TS',
    'MPEG2-PS',
    'MOV',
    'MPEG4',
    'MKV',
    'VOB',
    'MP4',
  ]

  static FILE_EXTENSION_NAME = {
    image: ['jpg', 'png', 'gif', 'jpeg'],
    video: ['wmv', 'avi', 'asf', 'flv', 'mpeg2-ts', 'mpeg2-ps', 'mov', 'mpeg4', 'mkv', 'vob', 'mp4'],
    sheet: ['xlsx', 'xls', 'xlsm', 'xlsb', 'xlt', 'xltx', 'xltm'],
    text: ['txt'],
    json: ['json'],
  }
}
